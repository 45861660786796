
import { defineComponent } from 'vue';

export default defineComponent({
  name: "FooterIcons",
  data () {
    return {
      links: {
        spotify: 'https://open.spotify.com/artist/3IP9EibiPZC8p1BKe5yNXU',
        itunes: 'https://music.apple.com/us/artist/mirrored-walls/1556086315',
        ytmusic: 'https://music.youtube.com/channel/UCcv4kHRrCxOlYPHbojNF1dQ',
        youtube: 'https://www.youtube.com/channel/UC_xz1y12Qm8OPqAYkrD34Mg',
        facebook: 'https://www.facebook.com/mirroredwalls',
        instagram: 'https://www.instagram.com/mirrored_walls'
      }
    }
  }
})
