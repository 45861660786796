import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/mw-submark.svg'
import _imports_1 from '@/assets/images/mw-text.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/",
    class: "header-link"
  }, {
    default: _withCtx(() => [
      (_ctx.isHomePage)
        ? (_openBlock(), _createBlock(_component_v_img, {
            key: 0,
            class: _normalizeClass(["logo-img", _ctx.imageLoading ? 'load' : '']),
            src: _imports_0,
            onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageLoaded())),
            height: "40vh"
          }, null, 8, ["class"]))
        : (_openBlock(), _createBlock(_component_v_img, {
            key: 1,
            class: "text-img",
            src: _imports_1
          }))
    ]),
    _: 1
  }))
}