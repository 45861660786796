import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/socials/spotify.svg'
import _imports_1 from '@/assets/images/socials/itunes.svg'
import _imports_2 from '@/assets/images/socials/ytmusic.svg'
import _imports_3 from '@/assets/images/socials/youtube.svg'
import _imports_4 from '@/assets/images/socials/facebook.svg'
import _imports_5 from '@/assets/images/socials/instagram.svg'


const _withScopeId = n => (_pushScopeId("data-v-2a724a18"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.spotify,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "",
                  "max-height": "30",
                  width: "30",
                  src: _imports_0
                })
              ], 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.itunes,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "social-icon",
                  "max-height": "30",
                  width: "30",
                  src: _imports_1
                })
              ], 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.ytmusic,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "social-icon",
                  "max-height": "30",
                  width: "30",
                  src: _imports_2
                })
              ], 8, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.youtube,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "social-icon",
                  "max-height": "30",
                  width: "30",
                  src: _imports_3
                })
              ], 8, _hoisted_4)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.facebook,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "social-icon",
                  "max-height": "30",
                  width: "30",
                  src: _imports_4
                })
              ], 8, _hoisted_5)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "4",
            sm: "2",
            class: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.links.instagram,
                target: "_blank"
              }, [
                _createVNode(_component_v_img, {
                  class: "social-icon",
                  "max-height": "30",
                  width: "30",
                  src: _imports_5
                })
              ], 8, _hoisted_6)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}