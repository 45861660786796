
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HeaderBar',

  data () {
    return {
      imageLoading: true
    }
  },

  props: {
    active: String
  },

  methods: {
    imageLoaded () {
      this.imageLoading = false
    }
  },

  computed: {
    isHomePage (): boolean {
      return useRouter().currentRoute.value.name == 'home'
    }
  }
})
